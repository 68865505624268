import React from "react";
import Logo from "../Logo/Logo";
import { Speaker, SpeakerOff } from "../Speaker/Speaker";
import Share from "../Share/Share";
import { useTheme } from "src/contexts/ThemeProvider";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { useGenerationStore } from "src/hooks/useGenerationStore";
import useVisitorDetection from "src/hooks/useVisitorDetection";
import gsap from "gsap";

interface HeaderProps {
    className?: string;
}

const Header: React.FC<HeaderProps> = ({ className }) => {
    const { onToggleSound, isToggledSound, onToggleModal } = useTheme();
    const { isStart, isStartWithParam } = useGenerationStore();
    const { progress, hoverProgress, setActiveSlide, activeSlide, tlInfiniteBgArray, tlInfiniteBg, lottie, timelineSlide1, isAnimating, setIsAnimating, timelineSlide2, timelineSlide3, timelineSlide4, timelineSlide5, timelineSlide6, timelineSlide7, timelineSlide8, setProgress } = useGenerationStore();
    const mainRef = React.useRef<HTMLDivElement>(null);
    const { setCookie, getCookie } = useVisitorDetection();

    const handleVisited = () => {

        if (getCookie('gg_introvisited') === null) {
            setCookie('gg_introvisited', 'true', 365);
        }

        if (activeSlide !== 8) {
            if (activeSlide === 1) {
                tlInfiniteBg
                    .timeScale(2)
                    .reverse()
                    .eventCallback('onReverseComplete', () => {
                        gsap.to(tlInfiniteBgArray.element, {
                            opacity: 0,
                            duration: 1,
                        });
                    });
                timelineSlide1
                    .timeScale(2)
                    .reverse()
                    .eventCallback('onReverseComplete', () => {
                        slide8Animation();
                    });
            }

            if (activeSlide === 2) {
                timelineSlide2.tlImage.timeScale(1.5).reverse();
                timelineSlide2.tl
                    .timeScale(1.5)
                    .reverse()
                    .eventCallback('onReverseComplete', () => {
                        slide8Animation();
                    });
            }

            if (activeSlide === 3) {
                timelineSlide3
                    .timeScale(3)
                    .reverse()
                    .eventCallback('onReverseComplete', () => {
                        if (lottie) {
                            lottie.stop();
                        }
                        slide8Animation();

                    });
            }

            if (activeSlide === 4) {
                timelineSlide4.tl.timeScale(3).reverse();
                const exit = gsap
                    .timeline({ paused: true })
                    .to(timelineSlide4.elementImage, { alpha: 0, duration: 1 });
                exit.timeScale(1)
                    .play()
                    .eventCallback('onComplete', () => {
                        slide8Animation();
                    });
            }

            if (activeSlide === 5) {
                timelineSlide5.tl.timeScale(3).reverse();

                const exit = gsap
                    .timeline({ paused: true })
                    .to(timelineSlide5.elementImage, { alpha: 0, duration: 1 });

                exit.timeScale(1)
                    .play()
                    .eventCallback('onComplete', () => {
                        slide8Animation();
                    });
            }

            if (activeSlide === 6) {
                timelineSlide6.tl.timeScale(3).reverse();
                const exit = gsap
                    .timeline({ paused: true })
                    .to(timelineSlide6.elementImage, { alpha: 0, duration: 1 });

                exit.timeScale(1)
                    .play()
                    .eventCallback('onComplete', () => {
                        slide8Animation();
                    });
            }

            if (activeSlide === 7) {
                timelineSlide7.tl
                    .timeScale(2)
                    .reverse()
                    .eventCallback('onReverseComplete', () => {
                        timelineSlide7.tlImageZ.timeScale(1).play();
                        slide8Animation();
                    });
            }

        } else {
            window.location.href = '/witness';
        }
    }

    const slide8Animation = () => {
        const allSlidesElement = document.querySelectorAll('.slide');
        allSlidesElement.forEach((slide, i) => {
            slide.classList.remove('active');
        });

        document
            .querySelector(`[tabindex="8"]`)
            ?.classList.add('active');
        setActiveSlide(8);
        document.documentElement.setAttribute(
            'data-theme',
            'dark'
        );
        setProgress(8);
        timelineSlide8.tl
            .timeScale(2)
            .play()
            .eventCallback('onComplete', () => {
                // animating.current = false;
                setIsAnimating(false);
            });
        timelineSlide8.tlImage.timeScale(1).play();
    }

    return (
        <header className={`fixed top-0 left-0 z-50 w-full h-full  pointer-events-none dark:before:absolute dark:before:bottom-0 dark:before:left-0 dark:before:w-full dark:before:bg-gradient-to-t from-white/15 to-transparent"  dark:before:h-100 ${className} ${!isStartWithParam && !isStart ? 'opacity-0 pointer-events-none' : ''} transition-all duration-500`} data-header ref={mainRef}>
            <div className="absolute top-5 left-5 xl:left-12.5 text-white flex gap-1 pointer-events-auto">
                <Logo />
            </div>
            <div className="absolute top-5 right-5 xl:right-12.5 text-white flex gap-7.5 items-center pointer-events-auto ">
                <button className="" onClick={onToggleModal}>
                    <Share />
                </button>
                <button onClick={onToggleSound} className="w-6 h-6 cursor-pointer">
                    {
                        isToggledSound ? <Speaker /> : <SpeakerOff />
                    }
                </button>
            </div>
            <div className="flex justify-between w-max md:flex-col absolute bottom-5 left-5 md:left-12.5 h-20 text-white dark:text-black">
            </div>
            <div className="absolute bottom-0 left-5 xl:left-12.5 text-white dark:text-black flex gap-1 flex-col transform origin-top-right pointer-events-auto group"  >
                <ProgressBar completed={progress} maxCompleted={8} width="2px" height="60px" />
                <span className="font-lexend text-3.2 absolute -right-4 transition-all duration-500 -translate-y-[100%]" style={
                    {
                        top: `${(progress / 8) * 100}%`, // Adjust translateY based on parent height
                    }

                }>{progress}</span>
                <span className="font-lexend text-3.2 absolute -right-4 transition-all duration-500 -translate-y-[100%] opacity-0 group-hover:opacity-50" style={
                    {
                        top: `${(hoverProgress / 8) * 100}%`, // Adjust translateY based on parent height
                    }

                }>{hoverProgress}</span>
            </div>
            <button onClick={handleVisited} className={`absolute bottom-5 right-5 xl:right-12.5 text-white dark:text-black flex gap-1 items-center transition-all pointer-events-auto cursor-pointer before:absolute before:w-full before:h-px before:bg-white dark:before:bg-black before:bottom-0 before:left-0 hover:before:scale-x-0 before:origin-left before:transition-all ${progress === 8 ? 'hidden' : ''}`}>
                <span>Skip Intro</span>
            </button>
        </header >
    );
}

export default Header;
