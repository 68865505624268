import { useTheme } from "src/contexts/ThemeProvider";
import clsx from "clsx";




const Toggle = () => {

    const { isToggledSound, onToggleSound } = useTheme();

    const activeToggle = clsx({
        'translate-x-full': isToggledSound,
    });


    return (
        <label className="bg-white/[0.5] w-[96px] h-11 rounded-full flex cursor-pointer items-center relative justify-around"
        >
            <input
                type="checkbox"
                checked={isToggledSound}
                className="hidden"
                onChange={onToggleSound}
            />
            < img src="./gg-slider-assets/images/icon/sound_off.svg" alt="sound off" className={`z-10 relative select-none ${isToggledSound ? 'opacity-20' : 'opacity-100'}`} />
            < img src="./gg-slider-assets/images/icon/sound_on.svg" alt="sound on" className={`z-10 relative select-none ${isToggledSound ? 'opacity-100' : 'opacity-20'}`} />
            <span className={`absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-3 rounded-full cursor-pointer w-12 h-12 transition-all ${activeToggle}`}>
            </span>
        </ label >

    );
}

export default Toggle;