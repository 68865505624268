import React, { useContext } from "react";
import { useAudio } from "src/hooks/useAudio";
import { useGenerationStore } from "src/hooks/useGenerationStore";

interface ThemeContextProps {
    onToggleSound: () => void;
    isToggledSound: boolean;
    progress: number;
    setProgressBar: (value: number) => void;
    onClickStartSlide: () => void;
    startSlide: boolean;
    activeSlide: number;
    // isStart: boolean;
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    onToggleModal: () => void;
    // setIsStart: (value: boolean) => void;
    setIsToggledSound: (value: boolean) => void;

}

const ThemeContext = React.createContext<ThemeContextProps | undefined>(undefined);

interface ThemeProviderProps {
    children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
    const { playAudio, pauseAudio } = useAudio();
    const [isToggledSound, setIsToggledSound] = React.useState(false);
    const [progress, setProgress] = React.useState(1);
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const onToggleSound = () => {
        if (isToggledSound) {
            pauseAudio();
        } else {
            playAudio();
        }
        setIsToggledSound(!isToggledSound);
    };


    const onToggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const setProgressBar = (slide: number) => {
        setProgress(slide);
    }

    // HANDLE START SLIDE
    const [startSlide, setStartSlide] = React.useState(false);
    const [activeSlide, setActiveSlide] = React.useState(0);
    // const [isStart, setIsStart] = React.useState(false);

    const {isStart,setIsStart} = useGenerationStore();

    const onClickStartSlide = () => {
        if (isToggledSound === false) {
            setIsToggledSound(true);
            playAudio();

        }
        setTimeout(() => {
            setStartSlide(true);
            setIsStart(true);
        }, 500)
        // setActiveSlide(1);
    };

    const value = {
        onToggleSound,
        isToggledSound,
        progress,
        setProgressBar,
        onClickStartSlide,
        startSlide,
        activeSlide,
        // isStart,
        onToggleModal,
        isModalOpen,
        setIsModalOpen,
        // setIsStart,
        setIsToggledSound
    };


    return (
        <ThemeContext.Provider value={value}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};
