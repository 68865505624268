import { useEffect, useState } from "react";
import { useGenerationStore } from "src/hooks/useGenerationStore";
import useVisitorDetection from "src/hooks/useVisitorDetection";


const CookiesModal = () => {
    const { activeSlide } = useGenerationStore();
    const { getCookie, setCookie } = useVisitorDetection();
    const [isVisited, setIsVisited] = useState(false);
    const visited = getCookie('gg_introvisited');

    useEffect(() => {
        visited === 'true' && activeSlide === 0 ? setIsVisited(true) : setIsVisited(false);

    }, [activeSlide, visited])

    const handleViewIntro = () => {
        setIsVisited(false);
    }

    const handleAlwaysSkip = () => {
        setCookie('gg_skip', 'true', 15);
    }

    return (
        <div className={`${isVisited ? 'flex' : 'hidden'} absolute w-[calc(100%_-_40px)] md:w-full flex-col bottom-10 left-1/2 transform -translate-x-1/2 md:translate-x-0 right-auto md:left-auto md:right-10 z-9999 bg-white max-w-lg p-7.5 gap-6 shadow-lg`}>
            <h5 className="font-lora">Explore Again</h5>
            <p className="font-lexend">You've seen the Gaza Genocide introduction before. How would you like to proceed?</p>
            <div className="flex-col flex md:flex-row gap-3.75">
                <button className="px-5 py-3.5 border-primary border hover:bg-primary hover:text-white transition-all" onClick={handleViewIntro}>
                    <span className="font-lexend">View Intro</span>
                </button>
                <a className="px-5 text-center py-3.5 border-primary border hover:bg-primary hover:text-white transition-all" href="/witness">
                    <span className="font-lexend">Skip this time</span>
                </a>
                <a className="text-center px-5 py-3.5 bg-primary hover:bg-secondary" onClick={handleAlwaysSkip} href="/witness">
                    <span className="text-white font-lexend">Always Skip</span>
                </a>
            </div>
        </div >
    );


}


export default CookiesModal;