import { useEffect, useRef } from "react";
import Toggle from "src/components/Toggle/Toggle";
import { useTheme } from "src/contexts/ThemeProvider";
import { useGenerationStore } from "src/hooks/useGenerationStore";

const Intro = () => {
    const { onClickStartSlide } = useTheme();
    const { isStart, isStartWithParam } = useGenerationStore();
    const btnRef = useRef<HTMLSpanElement>(null);
    useEffect(() => {
        const handleEnter = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                onClickStartSlide();
            }
        }
        window.addEventListener('keydown', handleEnter);
        return () => {
            window.removeEventListener('keydown', handleEnter);
        }
    }, []);

    return (
        <section className={`fixed flex items-center justify-center w-full h-screen ${isStart || isStartWithParam ? 'opacity-0 pointer-events-none' : ''} transition-all duration-500`}>
            <div className="flex flex-col items-center text-center">
                <div className="mb-7">
                    <Toggle />
                </div>
                <p className="font-bold text-lg mb-7.5 text-white font-lexend">Keep sound on for a more immersive experience</p>
                <button className="relative text-base text-white transition-all font-lexend before:absolute before:w-full before:h-px before:bg-white dark:before:bg-black before:bottom-0 before:left-0 before:origin-left before:transition-all animation-line-horizontal " onClick={onClickStartSlide}>
                    <span ref={btnRef}>Start with sound—click here!</span>
                </button>
            </div>
        </section>
    );
}


export default Intro;