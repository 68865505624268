import { useState } from "react";
import { Howl } from "howler";

export const useAudio = () => {
    const [sound, setSound] = useState<Howl | null>(null);

    const playAudio = () => {
        const audio = new Howl({
            src: ["./gg-slider-assets/sounds/ambient.mp3"],
            volume: 0.5,
            loop: true,
        });
        setSound(audio);
        audio.play();
    }

    const stopAudio = () => {
        if (sound) {
            sound.stop();
            setSound(null);
        }
    }

    const pauseAudio = () => {
        if (sound) {
            sound.pause();
        }
    }

    const resumeAudio = () => {
        if (sound) {
            sound.play();
        }
    }

    return { playAudio, stopAudio, pauseAudio, resumeAudio };
};
